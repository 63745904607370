import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Image from "~/components/image";
import ReviewsCarouselAbout from "../components/ReviewsCarouselAbout";
import { Link } from "gatsby";

const WhatExpect = ({ data }) => {
	const terms = data.strapiExpect;

	return (
		<Layout>
			<div className="">
				<GatsbySeo
					title="What to Expect"
					description="Setting the expectations right for your upcoming tour."
					// languageAlternates={[
					// 	{
					// 		hrefLang: "en-IN",
					// 		href: "https://www.artofbicycletrips.in/terms-conditions",
					// 	},
					// 	{
					// 		hrefLang: "en",
					// 		href: "https://www.artofbicycletrips.com/terms-conditions",
					// 	},
					// ]}
				/>
				<div className="my-20">
					<h1 className="font-bold">What to Expect</h1>
					<p className="font-light text-xl text-primary max-w-3xl mb-14">
						We put customer expectations at the centre of the way we work, building
						them into our products and service, and giving customers confidence in Art
						of Bicycle Trips.
					</p>

					{/* <ReactMarkdown
						className="prose prose-sm sm:prose"
						children={terms.description}
					/> */}
				</div>

				<section>
					<div>
						{data.strapiExpect.section.map((node) => {
							return (
								<div
									className="flex flex-col lg:flex-row items-center lg:justify-between py-20"
									key={node.id}
									id={node.id}
								>
									<div className="lg:w-1/2 lg:pr-8">
										{node.image && (
											<Image
												//alt="Itinerary Image"
												className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg "
												image={node.image}
												loading="lazy"
											/>
										)}
									</div>
									<div className="lg:w-1/2 lg:pl-6">
										<h2 className="text-2xl font-semibold mb-4 mt-4 lg:mt-0 uppercase">
											{node.heading}
										</h2>

										<ReactMarkdown
											className="prose prose-sm max-w-full"
											children={node.detail}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</section>
				<div className="flex space-x-4 mt-32 lg:mt-0">
					<Link
						to="/tours/?utm_source=about-us&utm_medium=cta-link&utm_campaign=find-trip"
						className="bg-primary tracking-wide font-medium text-white px-9 py-3 md:py-4 md:px-11 rounded-full"
					>
						Find trip
					</Link>
					<Link
						to="/design-trip/?utm_source=about-us&utm_medium=cta-link&utm_campaign=design-trip"
						className="bg-white border font-medium tracking-wide  border-primary text-primary px-6 py-3 md:py-4 md:px-8 rounded-full"
					>
						Design trip
					</Link>
				</div>
				<section id="reviews" className="my-16 md:my-36 overflow-hidden">
					<h2 className="mb-10 uppercase text-primary">
						Cyclists Love Us, You Will Too.
					</h2>

					{/* Reviews */}

					<ReviewsCarouselAbout />
				</section>
				{/* <section class="text-gray-600">
					<div class="container px-5 py-10 mx-auto">
						<div class="lg:w-3/4 w-full mx-auto text-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								class="inline-block w-8 h-8 text-primary mb-8"
								viewBox="0 0 975.036 975.036"
							>
								<path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
							</svg>
							<p class="leading-loose font-light">
								Jacky and I would like to thank you and all your staff, in particular
								Rakesh and Dipu, for a terrific cycle ride in Kerala. As experienced
								organisers of cycle tours in South Africa ourselves and participants in
								many other tours in Asia, Australia and Europe, we really can appreciate
								the superb attention to detail that you brought to this remarkable tour
								and cannot fault it in any way. It ranks amongst the very best we have
								experienced over many years. The cycling was challenging in parts for us
								over seventy year olds but always enjoyable. The other activities were
								also great. We saw many birds, giant squirrels and an elephant on our
								walk in the Tiger Reserve. Kayaking on the Periyar in the evening at
								Hornbill Camp was a birding highlight too and who would not enjoy the
								overnight stay on the backwaters in a luxury houseboat. Each place we
								stayed at was well chosen and different, giving us a real feel for this
								beautiful region with its rubber, tea and cardamom plantations. Rakesh
								and Dipu took exceptional care of us and pampered us throughout
								shielding us from traffic and making sure we had everything possible to
								add to our enjoyment as well as being knowledgeable about each area.
								They were superb. What you promised on your website was more than
								delivered and we feel that we have made new friends as well. The local
								food everywhere was very good and the cooking lessons that you arranged
								for us super fun as well as being delicious.
							</p>
							<span class="inline-block h-1 w-10 rounded bg-primary mt-8 mb-6"></span>
							<h2 class="text-gray-900 uppercase font-medium title-font tracking-wider text-sm">
								Jeremy M
							</h2>
							
						</div>
					</div>
				</section> */}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query WhatExpectQuery {
		strapiExpect {
			description
			section {
				heading
				detail
				image {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.3)
						}
					}
				}
			}
		}
	}
`;

export default WhatExpect;
